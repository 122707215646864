import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { content, Eventcontent, lookbookContent, Storescontent } from '../data';
import { IoIosMenu } from "react-icons/io";
import { MdClose } from 'react-icons/md';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { CgMail } from 'react-icons/cg';
import { AiOutlineGlobal } from 'react-icons/ai';
import { IoCallOutline } from 'react-icons/io5';

const VideoComponent = ({ src, autoPlay = true }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (videoRef.current) {
                const rect = videoRef.current.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <video
            ref={videoRef}
            width="100%"
            height="480"
            src={src}
            autoPlay={autoPlay}
            muted
            loop
            style={{ borderRadius: '5px', marginBottom: '16px' }}
            controls
        />
    );
};

export const CompanyProfile = () => {


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Beatnik | Everyday Fashion for Women</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320px" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Welcome to Beatnik, your destination for stylish and comfortable everyday fashion for women. Explore our latest collections, discover timeless wardrobe essentials, and find your new favorite outfits." />
                <meta property="og:title" content="Beatnik | Everyday Fashion for Women" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Welcome to Beatnik, your destination for stylish and comfortable everyday fashion for women. Explore our latest collections, discover timeless wardrobe essentials, and find your new favorite outfits." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Beatnik | Everyday Fashion for Women" />
                <meta name="twitter:description" content="Welcome to Beatnik, your destination for stylish and comfortable everyday fashion for women. Explore our latest collections, discover timeless wardrobe essentials, and find your new favorite outfits." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>


            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white justify-center items-center p-4 space-y-4">
                    <div className="w-full h-[95vh] relative no-bar overflow-auto">
                        <>


                            <div className="relative w-full min-h-[80vh] flex flex-col items-center justify-center p-4 md:p-4 gap-8">
                                <img loading="lazy" src="https://www.beatnik.in/cdn/shop/files/logo_150x.svg?v=1693997910" alt="Beatnik Logo" className='h-7 ' />
                                <div className="relative">
                                    <img loading="lazy"
                                        src="https://img.freepik.com/premium-photo/three-women-fashion-illustration-design-cloth_985626-70.jpg"
                                        alt="Background"
                                        className="w-full h-full object-cover opacity-60 mb-10"
                                    />
                                    <nav className=" absolute bottom-10 w-full bg-white bg-opacity-60 p-4 rounded-t-lg flex flex-col justify-center items-center gap-4">
                                        <Link
                                            to="/company-profile/profile"
                                            className="text-black text-2xl hover:text-gray-700"
                                        >
                                            Profile
                                        </Link>
                                        <Link
                                            to="/company-profile/lookbook"
                                            className="text-black text-2xl hover:text-gray-700"
                                        >
                                            Lookbook
                                        </Link>
                                        <Link
                                            to="/company-profile/stores"
                                            className="text-black text-2xl hover:text-gray-700"
                                        >
                                            Stores
                                        </Link>
                                        <Link
                                            to="/company-profile/franchisee-enquiry-form"
                                            className="text-black text-2xl hover:text-gray-700"
                                        >
                                            Franchisee Enquiry Form
                                        </Link>
                                        <Link
                                            to="/company-profile/event"
                                            className="text-black text-2xl hover:text-gray-700"
                                        >
                                            Events
                                        </Link>
                                    </nav>
                                </div>
                            </div>
                            <Footer />
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};



export const LookBook = () => {
    return (

        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Beatnik Lookbook | Make Your Mark</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320px" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Explore the Amazing Lookbook by Beatnik, a premier Western women's brand. Discover stylish and trendy outfits that blend classic Western charm with modern fashion. Perfect for the bold and adventurous woman." />
                <meta property="og:title" content="Beatnik Lookbook | Make Your Mark" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile/lookbook" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/6581701058-metalookbook.png" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Explore the Amazing Lookbook by Beatnik, a premier Western women's brand. Discover stylish and trendy outfits that blend classic Western charm with modern fashion. Perfect for the bold and adventurous woman." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Beatnik Lookbook | Make Your Mark" />
                <meta name="twitter:description" content="Explore the Amazing Lookbook by Beatnik, a premier Western women's brand. Discover stylish and trendy outfits that blend classic Western charm with modern fashion. Perfect for the bold and adventurous woman." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/6581701058-metalookbook.png" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile/lookbook" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>

            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white items-center p-2 space-y-4">
                    <div className="w-full h-[98vh] no-bar overflow-auto">
                        <>

                            <Navbar />
                            {/* <div className="bg-white text-black flex items-center justify-center p-10">
                            <h1 className="text-4xl">LOOKBOOK</h1>
                        </div> */}
                            {(lookbookContent ?? [])?.map((item, index) => (
                                item.type === 1 ? (
                                    <div className="w-full" key={index}>
                                        <img loading="lazy"
                                            src={item.url}
                                            alt="Placeholder"
                                            className="w-full h-auto rounded-lg shadow-md mb-4"
                                            onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                        />
                                    </div>
                                ) : (
                                    <VideoComponent key={index} src={item.url} />
                                )
                            ))}

                            <Footer />
                        </>

                    </div>
                </div>
            </div>
        </>
    )
}

export const Profile = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>Beatnik Everyday Fashion For Women</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Beatnik offers everyday fashion for women, blending comfort with style. Our collections feature versatile pieces that effortlessly transition from day to night, perfect for the modern woman seeking chic, practical, and timeless wardrobe essentials." />
                <meta property="og:title" content="Beatnik Everyday Fashion For Women" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile/profile" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/Profile-1.jpg" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Beatnik offers everyday fashion for women, blending comfort with style. Our collections feature versatile pieces that effortlessly transition from day to night, perfect for the modern woman seeking chic, practical, and timeless wardrobe essentials." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Beatnik Everyday Fashion For Women" />
                <meta name="twitter:description" content="Beatnik offers everyday fashion for women, blending comfort with style. Our collections feature versatile pieces that effortlessly transition from day to night, perfect for the modern woman seeking chic, practical, and timeless wardrobe essentials." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/6581701058-metalookbook.png" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile/profile" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>

            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white items-center p-2 space-y-4">
                    <div className="w-full h-[98vh] no-bar overflow-auto">
                        <>

                            <Navbar />
                            <div>
                                {(content ?? []).map((item, index) => (
                                    item.type === 1 ? (
                                        <div className="w-full" key={index}>
                                            <img loading="lazy"
                                                src={item.url}
                                                alt="Placeholder"
                                                className="w-full border h-auto rounded-lg shadow mb-4"
                                                onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                            />

                                        </div>
                                        //  <h1 className='text-center text-3xl font-semibold bg-blue-300 mb-3'>EBO Looks</h1>
                                    ) : (

                                        <VideoComponent key={index} src={item.url} />
                                    )
                                ))}
                            </div>
                            <Footer />
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Stores = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Explore The List Of All Our Brand Outlets</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320px" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Explore our locations to experience our stylish and comfortable everyday fashion for women. Visit us to find the latest collections and timeless wardrobe essentials." />
                <meta property="og:title" content="Explore The List Of All Our Brand Outlets" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile/stores" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/6011064119-store.jpeg" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Explore our locations to experience our stylish and comfortable everyday fashion for women. Visit us to find the latest collections and timeless wardrobe essentials." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Explore The List Of All Our Brand Outlets" />
                <meta name="twitter:description" content="Explore our locations to experience our stylish and comfortable everyday fashion for women. Visit us to find the latest collections and timeless wardrobe essentials." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/6011064119-store.jpeg" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile/stores" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>
            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white items-center p-2 space-y-4">
                    <div className="w-full h-[98vh] no-bar overflow-auto">
                        <>

                            <Navbar />
                            <h1 className="text-center text-2xl font-medium my-4 uppercase">Stores (EBO)</h1>
                            <h1 className="text-center text-2xl font-normal my-4 bg-blue-300 uppercase py-2">Running Stores</h1>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                                <div className="flex flex-col items-center mt-2 gap-2">
                                    <h2 className="text-lg font-normal uppercase tracking-wide ">Eastwood Jalandhar</h2>
                                    <img loading="lazy"
                                        src={Storescontent[0]?.url}
                                        alt="Jalandhar Store"
                                        className="w-full border h-auto rounded-lg shadow mb-4"
                                        onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                    />
                                </div>
                                <div className="flex flex-col items-center mt-2 gap-2 ">
                                    <h2 className="text-lg font-normal uppercase tracking-wide">ModelTown Ludhiana</h2>
                                    <img loading="lazy"
                                        src={Storescontent[1]?.url}
                                        alt="Ludhiana Store"
                                        className="w-full border h-auto rounded-lg shadow mb-4"
                                        onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                    />
                                </div>
                                <div className="flex flex-col items-center mb-4 gap-2">
                                    <h2 className="text-lg font-normal uppercase tracking-wide">Hanumanagarh</h2>
                                    <img loading="lazy"
                                        src={Storescontent[2]?.url}
                                        alt="Hanumanagarh Store"
                                        className="w-full border h-auto rounded-lg shadow mb-4"
                                        onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                    />
                                </div>
                            </div>
                            <h1 className="text-center text-2xl font-normal bg-blue-300 uppercase py-2 mb-6">Upcoming Stores</h1>
                            <div className="w-full  grid-cols-1 md:grid-cols-2 gap-4">
                                <VideoComponent src={Storescontent[3]?.url} />
                            </div>
                            <h1 className="text-center text-2xl font-normal bg-blue-300 uppercase py-2 my-6">Stores Look</h1>
                            <div className="w-full mt-6">
                                <VideoComponent src={Storescontent[4]?.url} />
                            </div>
                            <Footer />
                        </>
                    </div>
                </div>
            </div>
        </>

    )
}
export const FranchiseForm = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Franchise Enquiry Form | Partner with Us</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320px" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Interested in partnering with Beatnik? Fill out our Franchise Enquiry Form to learn more about our franchise opportunities. Join us in bringing stylish and comfortable everyday fashion for women to more locations." />
                <meta property="og:title" content="Franchise Enquiry Form | Partner with Us" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile/franchisee-enquiry-form" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/7824559637-franchise.png" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Interested in partnering with Beatnik? Fill out our Franchise Enquiry Form to learn more about our franchise opportunities. Join us in bringing stylish and comfortable everyday fashion for women to more locations." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Franchise Enquiry Form | Partner with Us" />
                <meta name="twitter:description" content="Interested in partnering with Beatnik? Fill out our Franchise Enquiry Form to learn more about our franchise opportunities. Join us in bringing stylish and comfortable everyday fashion for women to more locations." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/7824559637-franchise.png" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile/franchisee-enquiry-form" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>


            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white items-center p-2 space-y-4">
                    <div className="w-full h-[98vh] no-bar overflow-auto">
                        <>

                            <Navbar />
                            <div className="bg-white text-black flex flex-col items-center justify-center p-1">
                                <iframe aria-label='FRANCHISEE ENQUIRY Franchisee Enquiry Form' title='Zadley India Franchise Franchisee Enquiry Form' frameBorder="0" style={{ height: "280vh", width: '100%', border: 'none' }} src='https://forms.zohopublic.com/zadleyindiapvtltd/form/FRANCHISEEENQUIRYFORM/formperma/lw16HaXoiZcY9HeVz0-917PaYwrUzl7OASVsnuEKZs8'>
                                </iframe>
                            </div>
                            <Footer />
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Events = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Beatnik Events | Fashion Shows and Exclusive Launches</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, height=device-height, minimum-scale=1.0, maximum-scale=5.0" />
                <meta name="theme-color" content="#00badb" />
                <meta name="HandheldFriendly" content="True" />
                <meta name="MobileOptimized" content="320px" />
                <meta name="format-detection" content="telephone=yes" />
                <meta name="robots" content="index" />
                <meta name="description" content="Stay updated with Beatnik Events. Discover our upcoming fashion shows, exclusive launches, and special events. Join us to celebrate the latest trends in everyday fashion for women." />
                <meta property="og:title" content="Beatnik Events | Fashion Shows and Exclusive Launches" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://look.beatnik.in/company-profile/event" />
                <meta property="og:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-event.jpg" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="1200px" />
                <meta property="og:image:height" content="630px" />
                <meta property="og:description" content="Stay updated with Beatnik Events. Discover our upcoming fashion shows, exclusive launches, and special events. Join us to celebrate the latest trends in everyday fashion for women." />
                <meta property="og:site_name" content="Beatnik" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Beatnik Events | Fashion Shows and Exclusive Launches" />
                <meta name="twitter:description" content="Stay updated with Beatnik Events. Discover our upcoming fashion shows, exclusive launches, and special events. Join us to celebrate the latest trends in everyday fashion for women." />
                <meta name="twitter:image" content="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-event.jpg" />
                <link rel="alternate" hreflang="en-IN" href="https://look.beatnik.in/company-profile/event" />
                <link rel="icon" href="https://dmkuw9dqf74z8.cloudfront.net/content/beatnik-logo.svg" />
            </Helmet>


            <div className='bg-black w-full flex items-center justify-center'>
                <div className="w-full relative sm:w-[40%] h-screen flex-col bg-white items-center p-2 space-y-4">
                    <div className="w-full h-[98vh] no-bar overflow-auto">
                        <>

                            <Navbar />
                            <div className="bg-white text-black flex items-center justify-center mb-6">
                                <h1 className="text-4xl">Events</h1>
                            </div>
                            <div className="space-y-4">
                                <h2 className="text-center text-2xl font-normal my-4 bg-blue-300 uppercase py-2">Upcoming Events</h2>
                                {(Eventcontent ?? []).map((item, index) => (
                                    item.type === 1 ? (
                                        <div className="w-full " key={index}>
                                            <img loading="lazy"
                                                src={item.url}
                                                alt="Placeholder"
                                                className="w-full h-auto rounded-lg shadow-md mb-4"
                                                onError={(e) => e.target.src = 'https://dmkuw9dqf74z8.cloudfront.net/content/dummy-image-square%20(1).jpg'}
                                            />
                                        </div>
                                    ) : (
                                        <VideoComponent key={index} src={item.url} />
                                    )
                                ))}
                            </div>
                            <div className="">
                                <h1 className="text-center text-2xl font-normal my-4 bg-blue-300 uppercase py-2 mt-8">Previous Events</h1>

                                <h2 className="text-2xl font-normal ">SS-23 Radisson</h2>
                                <div className="w-full my-4">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/DbLVmAp0zlY"
                                        title="YouTube video"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <h2 className="text-2xl font-normal">CMAI Mumbai</h2>
                                <div className="w-full my-4">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/uRFdpYM1KwE"
                                        title="YouTube video"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>

                                <h2 className="text-2xl font-normal">CMAI North India</h2>
                                <div className="w-full my-4">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/WHwKh9-Qqkk"
                                        title="YouTube video"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <h2 className="text-2xl font-normal">Franchise India Show</h2>
                                <div className="w-full my-4">

                                    <VideoComponent src="https://dmkuw9dqf74z8.cloudfront.net/content/01 Beatnik Yashobhoomi Highlight - Final.mp4" autoPlay={false} />
                                </div>
                            </div>
                            <Footer />
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

const Navbar = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }
    return (
        <nav className="sticky top-0 bg-white p-2 z-[99999]">
            <div className="container mx-auto flex md:flex-col justify-between items-center ">
                <div>
                    <a href="/company-profile" className="text-black text-lg font-bold hover:text-black">
                        <img loading="lazy" src="https://www.beatnik.in/cdn/shop/files/logo_150x.svg?v=1693997910" alt="Beatnik Logo" className='h-7' />
                    </a>
                </div>
                <div className="hidden md:flex gap-5 mt-4">
                    <Link to="/company-profile/profile" className="text-black text-sm hover:text-black">Profile</Link>
                    <Link to="/company-profile/lookbook" className="text-black text-sm hover:text-black">Lookbook</Link>
                    <Link to="/company-profile/stores" className="text-black text-sm hover:text-black">Stores</Link>
                    <Link to="/company-profile/franchisee-enquiry-form" className="text-black text-sm hover:text-black">Franchisee Enquiry Form</Link>
                    <Link to="/company-profile/event" className="text-black text-sm hover:text-black">Events</Link>
                </div>
                <div className="md:hidden">
                    <button onClick={toggleDrawer} className="text-black text-sm hover:text-black">
                        <IoIosMenu size={'1.8rem'} />
                    </button>
                </div>
            </div>
            {isDrawerOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end">
                    <div className="bg-white w-64 p-4 flex flex-col gap-2">
                        <button onClick={toggleDrawer} className="text-black text-sm hover:text-black mb-4">
                            <MdClose size={'1.4rem'} />
                        </button>
                        <Link to="/company-profile/profile" className="block text-black text-sm hover:text-black mb-2" onClick={toggleDrawer}>Profile</Link>
                        <Link to="/company-profile/lookbook" className="block text-black text-sm hover:text-black mb-2" onClick={toggleDrawer}>Lookbook</Link>
                        <Link to="/company-profile/stores" className="block text-black text-sm hover:text-black mb-2" onClick={toggleDrawer}>Stores</Link>
                        <Link to="/company-profile/franchisee-enquiry-form" className="block text-black text-sm hover:text-black mb-2" onClick={toggleDrawer}>Franchisee Enquiry Form</Link>
                        <Link to="/company-profile/event" className="block text-black text-sm hover:text-black mb-2" onClick={toggleDrawer}>Events</Link>
                    </div>
                </div>
            )}
        </nav>
    )
}

const Footer = () => {
    return (
        <>
            <footer className="w-full bg-white pb-2">
                <div className="flex items-center ">
                    <div className="flex justify-between items-center w-full">
                        <div className="flex items-center gap-4">
                            <a
                                title="Instagram"
                                href="https://www.instagram.com/beatnikindia/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-black flex items-center p-1 bg-gray-200 rounded-full"
                            >
                                <FaInstagram color='#E4405F' size={'1.3rem'} />
                            </a>
                            <a
                                title="Facebook"
                                href="https://www.facebook.com/BeatnikIndia/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-black flex items-center p-1 bg-gray-200 rounded-full"
                            >
                                <FaFacebook color='blue' size={'1.3rem'} />
                            </a>
                            <a
                                title="Website"
                                href="https://www.beatnik.in"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-black flex items-center p-1 bg-gray-200 rounded-full"
                            >
                                <AiOutlineGlobal color='#34A853' size={'1.3rem'} />
                            </a>
                        </div>
                        <div className="flex items-center gap-3">
                            <a title="Phone" href="tel:9700700710" className="hover:text-black flex items-center p-1 bg-gray-200 rounded-full">
                                <IoCallOutline color='#34A853' size={'1.3rem'} />
                            </a>
                            <a
                                title="Email"
                                href="mailto:your-care@beatnik.in"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-black flex items-center p-1 bg-gray-200 rounded-full"
                            >
                                <CgMail color='#1DA1F2' size={'1.3rem'} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

