import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";

const Login = () => {
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [redirectId, setRedirectId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('ID');
    if (id) {
      setRedirectId(id);
    }
  }, []);

  const textChangeHandler = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const loginValidate = async (e) => {
    e.preventDefault();
    if (formData.userName === "" || formData.password === "") {
      return toast.error("Please enter your credentials");
    }
    setLoading(true);
    let requestBody = {
      username: formData.userName,
      passkey: formData.password,
      apptype: 30,
      longitude: "",
      latitude: ""
    };

    try {
      const response = await axios.post(
        "https://nodeapiadminpanel.singlagroups.com/login",
        requestBody
      );
      const data = response.data;
      if (data.status === 1) {
        toast.success(data.msg);
        console.log(data)
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        const expirationDate = new Date(new Date().getTime() + oneDayInMilliseconds);
        Cookies.set('token', data.data.token ?? data.data.access_token, { expires: expirationDate });
        if (redirectId) {
          history.push(`/catalog/${redirectId}`);
        } else {
          history.push("/");
        }
      } else {
        toast.error(data.msg);
      }
    } catch (err) {
      toast.error(err.response?.data?.msg || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen p-2">
      <form
        onSubmit={loginValidate}
        className="text-black min-h-[50vh] lg:w-[35vw] md:w-[40vw] w-full border shadow p-4 flex items-center justify-center flex-col gap-3"
        autoComplete="off"
      >
        <h1 className="w-full text-center text-2xl uppercase">Log In</h1>
        <div className="w-full mb-3 space-y-2" controlId="username">
          <span>UserName*</span>
          <div className="w-full">
            <input
              type="text"
              value={formData.userName}
              name="userName"
              onChange={textChangeHandler}
              maxLength="100"
              placeholder="Enter User Name"
              autoComplete="off"
              className="w-full p-2"
            />
          </div>
        </div>
        <div className="w-full mb-3 space-y-2" controlId="password">
          <label>Password</label>
          <div className="border flex items-center">
            <input
              type={show ? "text" : "password"}
              value={formData.password}
              name="password"
              onChange={textChangeHandler}
              maxLength="100"
              placeholder="Password"
              autoComplete="off"
              className="w-full border-none p-2"
            />
            {show ? (
              <FaRegEye
                className="cursor-pointer text-xl text-[#00000081] mx-2"
                onClick={() => setShow(false)}
              />
            ) : (
              <FaRegEyeSlash
                className="cursor-pointer text-xl text-[#00000081] mx-2"
                onClick={() => setShow(true)}
              />
            )}
          </div>
        </div>
        <button
          className="w-full bg-black text-white p-2 rounded"
          type="submit"
          disabled={loading}
        >
          {loading ? "Loading..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default Login;
