import React from "react";
import singlaLogo from "./assats/favicon.png";
import { Helmet } from "react-helmet";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Franchise from "./Zadleyindia/pages/Franchise";
import StoreLocator from "./Zadleyindia/pages/StoreLocator";
import Auth from "./Beatnik/Auth/Auth";
import 'react-toastify/dist/ReactToastify.css';
import { Profile, LookBook, CompanyProfile, FranchiseForm, Events, Stores } from "./component/CompanyProfile";
import Login from "./Beatnik/Auth/Login";
const OrderTracking = React.lazy(() => import("./component/OrderTracking"));
const SaleForceLog = React.lazy(() => import("./component/SaleForceLog"));
const PageNotFound = React.lazy(() => import("./component/PageNotFound"));
const ZedleyImageView = React.lazy(() => import("./component/ZedleyImageView"));
const BeatnikImageView = React.lazy(() => import("./Beatnik/BeatnikImageView"));
const Home = React.lazy(() => import("./Zadleyindia/pages/Home"));
const About = React.lazy(() => import("./Zadleyindia/pages/About"));
const Blog = React.lazy(() => import("./Zadleyindia/pages/Blog"));
const PostPage = React.lazy(() => import("./Zadleyindia/pages/PostPage"));
const Career = React.lazy(() => import("./Zadleyindia/pages/Career"));
const Contact = React.lazy(() => import("./Zadleyindia/pages/Contact"));
const Partner = React.lazy(() => import("./Zadleyindia/pages/Partner"));
const Apply = React.lazy(() => import("./Zadleyindia/pages/Apply"));
const PrivacyPolicy = React.lazy(() => import("./Zadleyindia/pages/PrivacyPolicy"));
const RedirectComp = React.lazy(() => import("./3-m.cc/RedirectComp"));
const Catalog = React.lazy(() => import("./Beatnik/Catalog/Catalog"));
function App() {
	if (window.location.host === "look.zadley.com") {
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Look | Zadley.com</title>
					<link rel="icon" href="https://lh3.googleusercontent.com/p/AF1QipPJA6I93ioNlYfTXizp4ZwTve8LlvdK-NwRh3YM=s1360-w1360-h1020" />
				</Helmet>
				<React.Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
					<BrowserRouter>
						<Switch>
							<Route path="/qrcode/:itemid/:comboid" component={ZedleyImageView}></Route>
							<Route path="/qrcode/:itemid" component={ZedleyImageView}></Route>
							<Route exact path="/" component={PageNotFound}></Route>
						</Switch>
					</BrowserRouter>
				</React.Suspense>
			</div>
		);
	} else if (window.location.host === "look.beatnik.in" ) {
		return (
			<div className="App">
				<React.Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
					<BrowserRouter>
						<Switch>
							<Route exact path="/company-profile" component={CompanyProfile}></Route>
							<Route path="/company-profile/profile" component={Profile} />
							<Route path="/company-profile/lookbook" component={LookBook} />
							<Route path="/company-profile/stores" component={Stores} />
							<Route path="/company-profile/franchisee-enquiry-form" component={FranchiseForm} />
							<Route path="/company-profile/event" component={Events} />
							<Route path="/qrcode/:itemid/:comboid" component={BeatnikImageView}></Route>
							<Route path="/qrcode/:itemid" component={BeatnikImageView}></Route>
							<Route path="/catalog/:catalogid" component={Catalog}></Route>
							<Route path="/auth" component={Auth}></Route>
							<Route path="/login" component={Login}></Route>
							<Route exact path="/" component={PageNotFound}></Route>
						</Switch>
					</BrowserRouter>
				</React.Suspense>
			</div>
		);
		//space was removed from host name
	} else if (window.location.host === "zadleyindia.com") {
		return (
			<div className="overflow-x-hidden">
				<React.Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
					<BrowserRouter>
						<Switch>
							<Route path="/about" component={About} ></Route>
							<Route path="/blog" component={Blog} ></Route>
							<Route path="/blog/:id" component={PostPage} ></Route>
							<Route path="/careers" component={Career} ></Route>
							<Route path="/contact" component={Contact} ></Route>
							<Route path="/join-us" component={Partner} ></Route>
							<Route path="/apply" component={Apply} ></Route>
							<Route path="/privacy-policy" component={PrivacyPolicy} ></Route>
							<Route path="/franchise" component={Franchise} ></Route>
							<Route path="/store-locator" component={StoreLocator} ></Route>
							<Route exact path="/" component={Home} ></Route>
							<Route path="*" component={PageNotFound} ></Route>
						</Switch>
					</BrowserRouter>
				</React.Suspense>
			</div>
		);
	} else if (window.location.host === "3-m.cc") {
		return (
			<div className="overflow-x-hidden">
				<React.Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
					<BrowserRouter>
						<Switch>
							<Route exact path="/:id" component={RedirectComp}></Route>
							<Route exact path="/" component={PageNotFound}></Route>
						</Switch>
					</BrowserRouter>
				</React.Suspense>
			</div>
		);
	} else {
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Order Timeline</title>
					<link rel="icon" href={singlaLogo} />
				</Helmet>
				<React.Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
					<BrowserRouter>
						<Switch>
							<Route exact path="/order/details/:id/:dbname" component={OrderTracking}></Route>
							<Route exact path="/order/details/:id" component={OrderTracking}></Route>
							<Route path="/push-data/resplog/:type" component={SaleForceLog}></Route>
							<Route path="/push-data/resplog" component={SaleForceLog}></Route>
							<Route exact path="/" component={PageNotFound}></Route>
						</Switch>
					</BrowserRouter>
				</React.Suspense>
			</div>
		);
	}
}

export default App;
