"use client"
import React from 'react'
import address1 from '../../assets/address1.svg'
import Vector1 from '../../assets/Vector2.svg';
import { Helmet } from 'react-helmet';
import Favicon from '../../assets/favicon.png';
import { useSpring, animated } from '@react-spring/web';
const Layout = React.lazy(() => import('../component/Layout'));
const StoreLocator = () => {
    const styles = useSpring({
        from: { opacity: '0' },
        to: { opacity: '1' },
    })
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About | ZadleyIndia</title>
                <link rel='icon' href={Favicon} />
            </Helmet>
            <div className='w-full relative flex items-center justify-center'>
                <img src={Vector1} className='z-[-20] top-0 absolute w-full' alt="vector" />
                <div className="sm:w-[84%] w-full flex items-center relative flex-col">
                    <div className="w-full mt-20">

                        <animated.div style={styles} >
                            <div className=" cursor-pointer flex items-center justify-center shadow md:h-40 md:w-60 h-24 w-44 border translate-x-[100%] relative rounded after:content-[''] after:absolute after:md:h-40 after:h-28 after:md:w-[7.5rem] after:w-[3rem] after:border-[#828282c1] after:left-0 after:border-r after:border-dashed after:border-b after:md:-bottom-[10.2rem] after:-bottom-[7rem] after:z-[-1]">
                                <a target='_blank' rel="noreferrer" href='https://www.google.com/maps/place/BEATNIK+-+Eastwood+Village/@31.2740163,75.6839209,15z/data=!4m6!3m5!1s0x391a5f6a8a563c43:0x24c3be24d418bcbc!8m2!3d31.2740163!4d75.6839209!16s%2Fg%2F11y3j30pyz?entry=ttu' className="h-full w-full flex items-center justify-center relative after:absolute after:h-full after:w-full after:rounded after:bg-[#4141417d] after:z-[-1]">
                                    <h2 className='text-center text-shadow-lg tracking-wider md:text-base text-xs text-white text-transparent bg-clip-text'>
                                        BEATNIK <br />
                                        A-59 First Floor Eastwood Village<br />
                                        <span className='md:text-lg text-sm'>Jalandhar</span>
                                    </h2>
                                </a>
                            </div>
                            <div className="md:h-80 md:w-80 h-60 w-60">
                                <img src={address1} className='h-full w-full' alt="" />
                            </div>
                        </animated.div>

                        <animated.div style={styles} className="w-full flex items-center justify-end md:-translate-y-40 ">
                            <div>
                                <div className=" cursor-pointer flex items-center -translate-x-[75%] justify-center shadow md:h-40 md:w-60 h-24 w-44 border relative rounded after:content-[''] after:absolute after:md:h-40 after:h-28 after:md:w-[7.5rem] after:w-[3rem] after:border-[#828282c1] after:border-l after:right-0 after:border-dashed after:border-b after:md:-bottom-[10.2rem] after:-bottom-[7rem] after:z-[-1]">
                                    <a target='_blank' rel="noreferrer" href='https://www.google.com/maps/place/BEATNIK+-+Model+Town,+Ludhiana/@30.8896528,75.8363743,17z/data=!3m1!4b1!4m6!3m5!1s0x391a83c1cd64ffa9:0x6fb5993950eb9ede!8m2!3d30.8896528!4d75.8389492!16s%2Fg%2F11vwk2n5k8?entry=ttu' className="h-full w-full flex items-center justify-center relative after:absolute after:h-full after:w-full after:rounded after:bg-[#4141417d] after:z-[-1]">
                                        <h2 className='text-center text-shadow-lg tracking-wider md:text-base text-xs text-white text-transparent bg-clip-text'>
                                            BEATNIK <br />
                                            434-L Model Town<br />
                                            <span className='md:text-lg text-sm'>Ludhiana</span>
                                        </h2>
                                    </a>
                                </div>
                                <div className="md:h-80 md:w-80 h-60 w-60">
                                    <img src={address1} className='h-full w-full scale-x-[-1]' alt="" />
                                </div>
                            </div>
                        </animated.div>
                        <animated.div style={styles} className=" md:-translate-y-80 " >
                            <div className=" cursor-pointer flex items-center justify-center shadow md:h-40 md:w-60 h-24 w-44 border translate-x-[100%] relative rounded after:content-[''] after:absolute after:md:h-40 after:h-28 after:md:w-[7.5rem] after:w-[3rem] after:border-[#828282c1] after:left-0 after:border-r after:border-dashed after:border-b after:md:-bottom-[10.2rem] after:-bottom-[7rem] after:z-[-1]">
                                <div className="h-full w-full flex items-center justify-center relative after:absolute after:h-full after:w-full after:rounded after:bg-[#4141417d] after:z-[-1]">
                                    <h2 className='text-center text-shadow-lg tracking-wider md:text-base text-xs text-white text-transparent bg-clip-text'>
                                        BEATNIK <br />
                                        249 Eastwood Village<br />
                                        <span className='md:text-lg text-sm'>Hanumangarh</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="md:h-80 md:w-80 h-60 w-60">
                                <img src={address1} className='h-full w-full' alt="" />
                            </div>
                        </animated.div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default StoreLocator
