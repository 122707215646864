export const content = [
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-1.jpg"
    },
    {
        type: 2,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/4276952098-vikas.mp4"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-2.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-3.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-4.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-5.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-6.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-7.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-8.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-9.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-10.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-11.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-12.jpg"
    },

    {
        type: 2,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEatnik-7.MP4"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-13.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-14.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-15.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-16.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-17.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-18.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-19.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Profile-20.jpg"
    },
]
export const lookbookContent = [
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 1.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 2.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 3.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 4.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 5.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 6.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 7.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 8.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 9.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 10.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 11.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 12.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 13.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 14.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 15.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 16.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 17.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 18.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 19.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 20.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 21.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 22.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 23.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 24.jpg"
    },
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/BEATNIK LOOKBOOK 25.jpg"
    },



]
export const Storescontent = [
    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Jalandhar (1).jpeg"
    },

    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Ludhiana.jpeg"
    },

    {
        type: 1,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Hanumangarh (1).jpeg"
    },
    {
        type: 2,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/8304555668-upcomingstore.mp4"
    },

    {
        type: 2,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Store+video.MOV"
    },
]

export const Eventcontent = [
    {
        type: 2,
        url: "https://dmkuw9dqf74z8.cloudfront.net/content/Event%20Video.mp4"
    },


]

// export const Mbosiscontent = [
//     {
//         type: 1,
//         url: "https://drive.google.com/thumbnail?id=1OiN6TXETwZBTz8fDL_HzjSVedq7CTnnF&sz=w1000"
//     },
//     {
//         type: 1,
//         url: "https://drive.google.com/thumbnail?id=1qTzOIma3qLT50RNFirxiNlcp3asZ36iy&sz=w1000"
//     },
//     {
//         type: 1,
//         url: "https://drive.google.com/thumbnail?id=1sh-OHXKFGh-Mzv-CK4KrzH-Iro6EUJqz&sz=w1000"
//     },


// ]