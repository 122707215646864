import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie'
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^\+?[1-9]\d{1,14}$/;
const headers = {
    'frontEnd': window.location.hostname
};
const Auth = () => {
    const [page, setPage] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const history = useHistory()
    const inputRef = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const handleChange = ({ target }, index) => {
        const newOtp = [...otp];
        newOtp[index] = target.value.substring(target.value.length - 1);
        if (!target.value) {
            setActive(index - 1);
        } else if (active === otp.length - 1) {
            setActive(otp.length - 1);
        } else {
            setActive(index + 1);
        }
        setOtp(newOtp);
    };

    useEffect(() => {
        inputRef?.current?.focus();
    }, [active]);

    useEffect(() => {
        if (resendTimer > 0) {
            const timer = setInterval(() => setResendTimer(resendTimer - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [resendTimer]);

    const handleSendOtp = async () => {
        try {
            setLoading(true);
            let payload = {};
            if (emailRegex.test(inputValue)) {
                payload = { email: inputValue };
            } else if (phoneRegex.test(inputValue)) {
                payload = { phone: inputValue };
            } else {
                alert('Please enter a valid email or phone number.');
                setLoading(false);
                return;
            }
            let data = await axios.post('https://nodeapiadminpanel.singlagroups.com/open/send-otp', payload, {
                headers,
            });
            let success = data?.response?.data?.status ? data?.response?.data?.msg : data?.status;
            let message = data?.response?.data?.msg ? data?.response?.data?.msg : data?.message;
            if (success) {
                setPage(1);
                toast.success('OTP sent successfully');
                setResendTimer(60);
            } else {
                toast.info(message);
            }
        } catch (e) {
            let message = e?.response?.data?.msg ? e?.response?.data?.msg : e?.message;
            toast.error(message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            setVerifyLoading(true);
            let payload = {};
            if (emailRegex.test(inputValue)) {
                payload = { otp: otp.join(''), email: inputValue };
            } else if (phoneRegex.test(inputValue)) {
                payload = { otp: otp.join(''), phone: inputValue };
            }
            let { data } = await axios.post('https://nodeapiadminpanel.singlagroups.com/open/verify-otp', payload, {
                headers,
            });
            if (data?.status) {
                const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
                const expirationDate = new Date(new Date().getTime() + oneDayInMilliseconds);
                Cookies.set('token', data.data.token, { expires: expirationDate });
                const id = queryParams.get('ID');
                queryParams.delete('ID');
                const newQueryString = queryParams.toString();
                history.push(`/catalog/${id}?${newQueryString}`);
                toast.success(data?.msg);
                setPage(1);
            } else {
                toast.error(data.msg)
            }
        } catch (e) {
            let message = e?.response?.data?.msg ? e?.response?.data?.msg : e?.message;
            toast.error(message)
        } finally {
            setVerifyLoading(false);
        }
    };

    const handleBack = () => {
        setPage(0);
        setOtp(new Array(4).fill(""));
        setActive(0);
    };



    return (
        <>
            <ToastContainer />

            <div className='fixed flex items-center justify-center z-[9999999] inset-0 px-2'>
                {page ? (
                    <div className='bg-white sm:w-[40vw] w-full h-[40vh] relative rounded-t p-3 flex items-start flex-col justify-center gap-2' onClick={(e) => e.stopPropagation()}>
                        <FaLongArrowAltLeft className='absolute left-5 top-4 text-xl text-gray-600 cursor-pointer' onClick={handleBack} />
                        <h1 className='text-2xl text-center w-full'>Verify OTP</h1>
                        <div className="w-full flex justify-center items-center gap-7 mt-5">
                            {otp.map((_, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <input
                                            ref={active === index ? inputRef : null}
                                            type="number"
                                            value={otp[index]}
                                            onChange={(e) => handleChange(e, index)}
                                            className="sm:w-12 sm:h-12 h-10 w-10 border-b-2 bg-[#ffffff9c] border-[#787878] outline-none text-center font-semibold text-xl spin-button-none focus:border-gray-700 focus:text-gray-700 text-[#787878] transition"
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="w-full flex items-center justify-center mt-5 space-x-4">
                            <button
                                className="border slider mt-3 bg-[#ffffff9c] border-[#c6c6c6] py-2 px-8 flex items-center justify-center w-[10rem] text-sm text-center rounded transition-all tracking-wide hover:tracking-widest relative"
                                onClick={handleSubmit}
                            >
                                {verifyLoading ? <div className="h-5 w-5 rounded-full border-t-2 border-l-2 animate-spin " /> : 'Verify'}
                            </button>
                        </div>
                        <div className="w-full flex items-center justify-center mt-3">
                            {
                                resendTimer > 0 ? <span className='text-sm'>Resend OTP in {resendTimer}s</span> :
                                    <button
                                        className="slider mt-3 bg-[#ffffff9c] border-[#c6c6c6] flex items-center justify-center w-[10rem] text-sm text-center rounded transition-all tracking-wide hover:tracking-widest relative"
                                        onClick={handleSendOtp}
                                        disabled={resendTimer > 0}
                                    >
                                        {loading ? <div className="h-5 w-5 rounded-full border-t-2 border-l-2 animate-spin " /> : 'Resend OTP'}
                                    </button>
                            }
                        </div>
                    </div>
                ) : (
                    <div className='bg-white sm:w-[40vw] w-full h-[40vh] rounded-t p-3 flex items-start flex-col justify-center gap-2' onClick={(e) => e.stopPropagation()}>
                        <h1 className='text-2xl text-center w-full mb-8'>Authenticate</h1>
                        <label htmlFor="contact" className='text-gray-600 mb-4'>Phone *</label>
                        <input
                            id="contact"
                            className='border-b w-full p-2 '
                            type="number"
                            placeholder='Please tell us Phone'
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <button
                            className="border slider text-xs mt-5 bg-[#ffffff9c] border-[#c6c6c6] py-2 px-8 flex items-center justify-center w-[8.4rem] text-center rounded transition-all tracking-wide hover:tracking-widest relative"
                            onClick={handleSendOtp}
                        >
                            {loading ? <div className="h-5 w-5 rounded-full border-t-2 border-l-2 animate-spin " /> : 'Send OTP'}
                        </button>
                        <p className='text-xs text-center w-full mt-2 text-gray-500'>*You will receive otp on your whatsapp</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default Auth;
