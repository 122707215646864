import React from 'react'
const Layout = React.lazy(() => import('../component/Layout'));
const Franchise = () => {
    return (
        <Layout>
            <div className="w-full flex items-center justify-center p-2 franchise-bg after:content-[''] relative after:w-full after:h-full after:absolute after:bg-[#242c4098] -z-1">
                <div className="w-full min-h-[35vh] flex flex-col items-center justify-center z-10 gap-5 my-10">
                    <div className="mt-3 md:text-5xl text-2xl text-white flex items-center justify-center font-light uppercase tracking-wider 
                    ">
                        We Are Offering
                    </div>
                    <h1 className="md:text-8xl text-5xl mt-3 p-2 z-[2] rounded bg-gradient-to-r from-red-800 via-yellow-600 to-yellow-500 text-white tracking-widest uppercase">
                        Franchise
                    </h1>
                    <h3 className="w-full md:text-2xl mt-7 text-center font-bold bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent tracking-wider uppercase">
                        Join the market's fastest-growing women's wear Brand
                    </h3>

                </div>
            </div>
            <div className="">
                <iframe aria-label='FRANCHISEE ENQUIRY FORM' title='Zadley India Franchise form' frameborder="0" style={{ height: "245vh", width: '99%', border: 'none' }} src='https://forms.zohopublic.com/zadleyindiapvtltd/form/FRANCHISEEENQUIRYFORM/formperma/lw16HaXoiZcY9HeVz0-917PaYwrUzl7OASVsnuEKZs8?src=Website%20(Zadley%20India)'></iframe>
            </div>

        </Layout>
    )
}

export default Franchise